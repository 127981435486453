import React from 'react';

interface CoverImage {
  src: string;
  alt: string;
  sizes: string;
  srcset: string;
}

interface FdiTargetCardData {
  iconSrc: string;
  title: string;
  factNumber: string;
  description: string;
  backHeading: string;
  backDescription: string;
  backImageSrc: string;
}

interface StickySectionProps {
  goalTag: string;
  goalHeading: string;
  goalDescription?: string;
  goalNumber: string;
  mathSign?: string;
  coverImage?: CoverImage;
  fdiCards?: FdiTargetCardData[];
}

interface HeroSectionProps {
  heroData: {
    tagline: string;
    heading: string;
    description: string;
  };
  stickySections: StickySectionProps[];
}

const HeroSection: React.FC<HeroSectionProps> = ({ heroData, stickySections }) => {

  return (
    <div className="hero-section">
      {/* Hero section */}
      <div className="sticky-section top">
        <section className={`entrance-message ${!heroData.heading ? "is-empty" : ""}`}>
          {heroData.heading && <div className="w-layout-blockcontainer container w-container">
            <div className="hero-content-div">
              <div className="heading-set display">
                <div className="limited-heading-set display">
                  <div className="tagline-heading">{heroData.tagline}</div>
                  <div className="heading-set-3">
                    <h1 className="heading-1">{heroData.heading}</h1>
                    <div className="paragraph-large">{heroData.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </section>
      </div>

      {/* Sticky sections */}
      {stickySections.map((section, index) => (
        <div key={index} className={`sticky-section ${section.goalTag === 'GOAL 3' ? 'sticky-200' : index === 1 ? 'sticky-160' : ''}`}>
          <div className="overlapping-section mobile-clear">
            <div className="container-large">
              <div className={`overlapping-bento ${index === 0 ? 'first-layer' : 'second-layer'}`}>
                <div className={`w-layout-grid overlapping-grid-system ${!section.goalNumber && 'one-grid-system'}`}>
                  <div className={`limited-heading-set`}>
                    <div className="tagline-heading">{section.goalTag}</div>
                    <div className="heading-set-3">
                      <h2 className="h3-heading">{section.goalHeading}</h2>
                      {section.goalDescription && <div className="description">{section.goalDescription}</div>}
                    </div>
                  </div>
                  {section.goalNumber && <h1 className="number-display">
                    {section.goalNumber}
                    <span className="math-sign">{section.mathSign}</span>
                  </h1>}
                </div>
                {section.coverImage && (
                  <div className="cover-image absolute">
                    <img
                      src={section.coverImage.src}
                      alt={section.coverImage.alt}
                      sizes={section.coverImage.sizes}
                      srcSet={section.coverImage.srcset}
                      className="cover-visual"
                    />
                  </div>
                )}
                {section.fdiCards && (
                  <div className="fdi-targets-grid">
                    {section.fdiCards.map((card, cardIndex) => (
                      <div key={cardIndex} className="fdi-target-card">
                        <div className="fdi-targetcard-top">
                          <img src={card.iconSrc} loading="lazy" alt="" className="fdi-target-icon" />
                          <h3 className="mini-card-title">{card.title}</h3>
                        </div>
                        <div className="fdi-targetcard-bottom">
                          <div className="number-fact mid bold">{card.factNumber}</div>
                          <div className="description white mini">{card.description}</div>
                        </div>
                        <div className="flipcard-fdi-target-back">
                          <h2 className="h4-heading">
                            <strong>{card.backHeading}</strong>
                          </h2>
                          <div className="description mini">{card.backDescription}</div>
                          <img src={card.backImageSrc} alt="" className="flipping-card-image back" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeroSection;
