import { APIError, type BaseResponse } from 'models/baseResponse'
import config from '../../config/config'
import type { ActionResponse } from 'models/actionPlan'

export class API {
  static prefix = '_vti_bin/InvestAPI/StrategyService.svc'

  private static async post(method: string, webServiceUrl: string = config.getWebServiceUrl()) {
    let resp
    try {
      resp = await fetch(
        `${webServiceUrl}/${API.prefix}/${method}`,
        {
          method: 'POST'
        }
      )
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }
    if (resp.status !== 200) {
      const { error } = await resp.json()
      throw new APIError({ status: resp.status, message: error, code: 'INTERNAL' })
    }
    return await resp.json()
  }

  static async getActionData(): Promise<BaseResponse<ActionResponse>> {
    let result: BaseResponse<ActionResponse>

    try {
      result = await this.post('ActionPlan/GetItems')
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }

    return await result
  }

  static async subscribe(email: string): Promise<BaseResponse<boolean>> {
    let result: BaseResponse<boolean>

    try {
      result = await this.post(`Subscribe?email=${email}`, config.getWebServiceUrl().replace("/fdi-strategy", ""))
    } catch (error) {
      throw new APIError({ code: 'INTERNAL', message: error?.toString() ?? '', status: 0 })
    }

    return await result
  }
}
