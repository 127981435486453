import { useContent } from 'hooks/useContent';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState, type FC } from 'react'
import SubscriberLink from '../forms/SubscriberLink';

export interface IMainHeaderProps {
  optionalSubMenu?: React.ReactNode;
  isAnimateMenu?: boolean;
}

export const MainHeader: FC<IMainHeaderProps> = ({ optionalSubMenu, isAnimateMenu = false }) => {
   const { content } = useContent();
  const router = useRouter();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const [isTabletDropdownOpen, setIsTabletDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!isAnimateMenu) return; // Eğer animasyon istenmiyorsa hiçbir işlem yapılmayacak

    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Scroll durumu kontrolü
      if (currentScrollY > lastScrollY) {
        setIsScrollingDown(true); // Aşağı scroll
      } else {
        setIsScrollingDown(false); // Yukarı scroll
      }

      setIsScrolled(currentScrollY > 50); // 50px'yi geçince stil değiştir

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimateMenu]);

  const toggleDropdown = () => {
    // setIsDropdownOpen((prev) => !prev);
    setIsDropdownOpen(false);
  };

  const toggleMobileDropdown = () => {
    // setIsMobileDropdownOpen((prev) => !prev);
    setIsMobileDropdownOpen(false);
  };

  const toggleTabletDropdown = () => {
    // setIsTabletDropdownOpen((prev) => !prev);
    setIsTabletDropdownOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    const overlayElement = document.getElementById('w-nav-overlay-0');
    const navMenu = document.querySelector('.nav-menu.hide-tablet.w-nav-menu');

    if (overlayElement && navMenu) {
      if (isMenuOpen) {
        overlayElement.style.display = 'none';
        overlayElement.style.height = '0';

        // Menü kapanınca eski yerine geri taşı
        document.querySelector('.navbar-v2')?.appendChild(navMenu);

        // Stilleri temizle ve attribute'u kaldır
        navMenu.removeAttribute('style');
        navMenu.removeAttribute('data-nav-menu-open');
      } else {
        overlayElement.style.display = 'block';
        overlayElement.style.height = '38066px';

        // Menü açılınca nav-menu'yu overlay içine taşı
        overlayElement.appendChild(navMenu);

        // Gerekli stilleri ve attribute'u ekle
        navMenu.setAttribute(
          'style',
          'transition: all 400ms ease; transform: translateY(0px) translateX(0px);'
        );
        navMenu.setAttribute('data-nav-menu-open', 'true');
      }
    }
  };

  const getNewLocaleLink = (newLocale: string) => {
    const currentPath = router.asPath; // Mevcut sayfanın path'i
    return `${newLocale}${currentPath && currentPath !== "/" ? currentPath : ""}`;
  };

  return (
    <div
      data-animation="default"
      className="navbar-wrapper w-nav"
      style={isAnimateMenu ? {
        willChange: 'background',
        backgroundColor: isScrolled
          ? isScrollingDown
            ? 'rgba(255, 255, 255, 0.063)' // Aşağı scroll
            : 'rgba(255, 255, 255, 0.024)' // Yukarı scroll
          : 'rgba(255, 255, 255, 0)',
        transform: isScrolled
          ? isScrollingDown
            ? 'translate3d(0px, -80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)' // Aşağı scroll
            : 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)' // Yukarı scroll
          : 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', // İlk yükleniş
        transformStyle: 'preserve-3d',
        transition: 'transform 400ms ease, background-color 400ms ease', // Animasyon 400ms
      } : {}}
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="3642eec2-12cd-7ce3-aa3d-f89ed0a9a2ce"
      role="banner"
      data-duration="400"
      data-doc-height="1"
    >
      <div className="navbar-v2">
        <div className="nav-container">
          <a href="#" className="nav-logo-link hide-desktop w-nav-brand">
            <img src="/images/EN-FDI-Strategy-Report.png" loading="lazy" sizes="(max-width: 991px) 101.09375px, 100vw" srcSet="/images/EN-FDI-Strategy-Report-p-500.png 500w, images/EN-FDI-Strategy-Report.png 1071w" alt="" className="navigation-logo primary" />
            <div className="nav-divider full-height"></div>
            <img src="/images/Investment-Office-SVG-Logo.svg" loading="lazy" alt="" className="navigation-logo secondary" />
          </a>
          <div className="navbar-mobile-button-set">
            <div data-hover="false" data-delay="300" data-w-id="3642eec2-12cd-7ce3-aa3d-f89ed0a9a2d6" className="nav-dropdown w-dropdown">
              <div
                className={`dropdown-link w-dropdown-toggle ${isTabletDropdownOpen ? 'w--open' : ''}`}
                onClick={toggleTabletDropdown}
              >

                <div className="button-icon-left">{content.headerMenu?.currentLanguage.icon}</div>
                <div className="button-text">{content.headerMenu?.currentLanguage.code}</div>
              </div>
              <nav
                className={`nav-dropdown-list w-dropdown-list ${isTabletDropdownOpen ? 'w--open' : ''}`}
                style={{
                  maxHeight: isTabletDropdownOpen ? '300px' : '0',
                  transition: 'max-height 400ms ease',
                  overflow: 'hidden',
                }}
              >
                <div className="nav-dropdown-list-medium">
                  <div className="nav-dropdown-content">
                    <div className="dropdown-side-menu">
                      <div className="dropdown-links-list">
                        <div className="dropdown-links-group">
                          {content.headerMenu.optionalLanguages.map((lang, index) => (
                            <a key={index} href={getNewLocaleLink(lang.link)} className="dropdown-link w-inline-block">
                              <div className={`dropdown-icon-wrapper ${lang.label.toLowerCase()}`}></div>
                              <div>{lang.code}</div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <div className="menu-button w-nav-button" onClick={toggleMenu}>
              <div className="button-icon-left"></div>
            </div>
            <div className="download-button-mobile-nav mobile-none">
              <SubscriberLink data-w-id="88602e33-63d9-9c80-d71f-f5d64ae8de43" href="https://www.invest.gov.tr/en/Documents/turkiye-foreign-direct-investment-strategy-2024-2028.pdf" target="_blank" className="button-left-icon w-inline-block">
                <div className="button-icon-left"></div>
                <div className="text-block-4">{content.headerMenu?.downloadButton.text}</div>
              </SubscriberLink>
            </div>
          </div>
          <nav role="navigation" className="nav-menu hide-tablet w-nav-menu">
            <div className="w-layout-grid nav-menu-grid">
              <div id="w-node-_3642eec2-12cd-7ce3-aa3d-f89ed0a9a2f5-d0a9a2ce" className="nav-content-left">
                <Link href="/" aria-current="page" className="nav-logo-link hide-tablet w-nav-brand w--current">
                  <img src="/images/EN-FDI-Strategy-Report.png" loading="lazy" sizes="(max-width: 991px) 100vw, 151.640625px" srcSet="/images/EN-FDI-Strategy-Report-p-500.png 500w, images/EN-FDI-Strategy-Report.png 1071w" alt="" className="navigation-logo primary" />
                  <div className="nav-divider full-height"></div>
                  <img src="/images/Investment-Office-SVG-Logo.svg" loading="lazy" alt="" className="navigation-logo secondary" />
                </Link>
              </div>
              <div className="nav-content-right">
                <div className="nav-links-list">
                  <div data-hover="false" data-delay="300" data-w-id="3642eec2-12cd-7ce3-aa3d-f89ed0a9a2fc" className="nav-dropdown w-dropdown">
                    <div
                      className={`dropdown-link desktop-none w-dropdown-toggle ${isMobileDropdownOpen ? 'w--open' : ''}`}
                      onClick={toggleMobileDropdown}
                    >
                      <div className="button-icon-left">{content.headerMenu?.currentLanguage.icon}</div>
                      <div className="button-text">{content.headerMenu?.currentLanguage.code}</div>
                    </div>
                    <nav
                      className={`nav-dropdown-list w-dropdown-list ${isMobileDropdownOpen ? 'w--open' : ''}`}
                      style={{
                        maxHeight: isMobileDropdownOpen ? '300px' : '0',
                        transition: 'max-height 400ms ease',
                        overflow: 'hidden',
                      }}
                    >
                      <div className="nav-dropdown-list-medium">
                        <div className="nav-dropdown-content">
                          <div className="dropdown-side-menu">
                            <div className="dropdown-links-list">
                              <div className="dropdown-links-group">
                                {content.headerMenu.optionalLanguages.map((lang, index) => (
                                  <a key={index} href={getNewLocaleLink(lang.link)} className="dropdown-link w-inline-block">
                                    <div className={`dropdown-icon-wrapper ${lang.label.toLowerCase()}`}></div>
                                    <div>{lang.code}</div>
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>

                  {content.headerMenu.menuItems.map((menuItem, index) => {
                    return <Link href={menuItem.link} aria-current="page" key={index}
                      className={`nav-button w-nav-link ${router.pathname === menuItem.link ? 'w--current' : ''}`}
                    >
                      {menuItem.name}
                    </Link>
                  })}

                  <div className="download-button-mobile-nav">
                    <SubscriberLink data-w-id="88602e33-63d9-9c80-d71f-f5d64ae8de43" href="https://www.invest.gov.tr/en/Documents/turkiye-foreign-direct-investment-strategy-2024-2028.pdf" target="_blank" className="button-left-icon w-inline-block">
                      <div className="button-icon-left"></div>
                      <div className="text-block-4">{content.headerMenu?.downloadButton.text}</div>
                    </SubscriberLink>
                  </div>
                </div>
                <div className="nav-divider"></div>
                <div className="nav-button-group">
                  <div data-hover="false" data-delay="300" data-w-id="3642eec2-12cd-7ce3-aa3d-f89ed0a9a31e" className="nav-dropdown w-dropdown">
                    <div
                      className={`dropdown-link w-dropdown-toggle ${isDropdownOpen ? 'w--open' : ''}`}
                      onClick={toggleDropdown}>
                      <div className="button-icon-left">{content.headerMenu?.currentLanguage.icon}</div>
                      <div className="button-text">{content.headerMenu?.currentLanguage.code}</div>
                    </div>
                    <nav
                      className={`nav-dropdown-list w-dropdown-list ${isDropdownOpen ? 'w--open' : ''}`}
                      style={{
                        maxHeight: isDropdownOpen ? '300px' : '0',
                        transition: 'max-height 400ms ease',
                        overflow: 'hidden',
                      }}
                    >
                      <div className="nav-dropdown-list-medium">
                        <div className="nav-dropdown-content">
                          <div className="dropdown-side-menu">
                            <div className="dropdown-links-list">
                              <div className="dropdown-links-group">
                                {content.headerMenu.optionalLanguages.map((lang, index) => (
                                  <a key={index} href={getNewLocaleLink(lang.link)} className="dropdown-link w-inline-block">
                                    <div className={`dropdown-icon-wrapper ${lang.label.toLowerCase()}`}></div>
                                    <div>{lang.code}</div>
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <SubscriberLink data-w-id="88602e33-63d9-9c80-d71f-f5d64ae8de43" href="https://www.invest.gov.tr/en/Documents/turkiye-foreign-direct-investment-strategy-2024-2028.pdf" target="_blank" className="button-left-icon w-inline-block">
                    <div className="button-icon-left"></div>
                    <div className="text-block-4">{content.headerMenu?.downloadButton.text}</div>
                  </SubscriberLink>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{ display: 'none' }}></div>
      {optionalSubMenu && (
        <div data-w-id="3642eec2-12cd-7ce3-aa3d-f89ed0a9a337" className="index">
          {optionalSubMenu}
        </div>
      )}
    </div >
  )
}
