export default {
  getBaseUrl: () => {
    if (process.env.NEXT_PUBLIC_APP_URL) {
      return process.env.NEXT_PUBLIC_APP_URL
    }

    if (process.env.AZURE_WEB_URL) {
      return `https://${process.env.AZURE_WEB_URL}`
    }

    return 'http://localhost:3000'
  },

  getWebServiceUrl: () => {
    if (process.env.PUBLIC_WEB_SERVICE_URL) {
      return process.env.PUBLIC_WEB_SERVICE_URL
    }
    return 'https://www.invest.gov.tr/en/fdi-strategy'
  },

  getPublicImageDomain: () => {
    if (process.env.PUBLIC_IMAGE_DOMAIN) {
      return process.env.PUBLIC_IMAGE_DOMAIN
    }
    return 'https://f.invest.gov.tr'
  },
}
