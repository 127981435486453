import React, { useState } from 'react';
import Modal from '../Modal';
import { API } from '@/lib/api';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SubscriberModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);


    const onCloseHandler = () => {
        setEmail('');
        setIsLoading(false);
        setIsError(null);
        setIsSuccess(false);
        onClose();
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleSubmit = async () => {

        setIsLoading(true);
        setIsError(null);
        setIsSuccess(false);

        try {

            if (!email) {
                throw new Error('Email is required');
            }

            if (!emailRegex.test(email)) {
                throw new Error('Please enter a valid email address.');
            }

            await API.subscribe(email);

            setIsSuccess(true);

            setEmail('');
        } catch (error) {
            setIsError('An error occurred while submitting the form.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (

        <Modal isOpen={isOpen} onClose={onClose} className='light-mint'>
            {/* Cover Image */}
            <div className="cover-image absolute modal">
                <img
                    src="https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66d828e83a288d00492bb9f1_Flying%20iPhone%2012%20Left%20View%203.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 83vw, (max-width: 767px) 280px, 400px"
                    srcSet="https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66d828e83a288d00492bb9f1_Flying%20iPhone%2012%20Left%20View%203-p-500.png 500w, https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66d828e83a288d00492bb9f1_Flying%20iPhone%2012%20Left%20View%203-p-800.png 800w, https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66d828e83a288d00492bb9f1_Flying%20iPhone%2012%20Left%20View%203.png 1200w"
                    alt=""
                    className="modal-image"
                />
            </div>

            {/* Modal Form */}
            <div className="w-layout-grid modal-grid-system">
                <div className="limited-heading-set">
                    <div className="tagline-heading">FDI&nbsp;STRATEGY</div>
                    <div className="heading-set-3">
                        <h2 className="h3-heading">Subscribe to the Türkiye's FDI&nbsp;Agenda</h2>
                    </div>
                </div>
                <div
                    id="w-node-a3568ff9-cc06-f5c1-3306-e3f4ff723fb4-b45ce221"
                    className="a-filter-filter-form-block-top w-form"
                >
                    <div
                        id="wf-form-Email"
                        data-name="Email"
                        className="a-filter-form-top"
                        data-wf-page-id="66d8269ed48e78e5b45ce221"
                        data-wf-element-id="a3568ff9-cc06-f5c1-3306-e3f4ff723fb5"
                        aria-label="Email"
                    >
                        <div className="a-filter-search">
                            <div className="a-filter-search-icon-wrapper">
                                <div className="fa-solid-span small"></div>
                            </div>
                            <input
                                className="a-filter-search-text-field jetboost-list-search-input-xr5q w-input"
                                maxLength={256}
                                name="name"
                                data-name="Name"
                                placeholder="Email..."
                                type="email"
                                id="search"
                                value={email}
                                onChange={handleEmailChange}
                                control-id="ControlID-1"
                                required
                                disabled={isLoading}
                            />
                            <input
                                type="submit"
                                data-wait="Please wait..."
                                className="button-left-icon w-button"
                                value={isLoading ? 'Loading...' : 'Subscribe Now'}
                                control-id="ControlID-2"
                                disabled={isLoading}
                                onClick={handleSubmit}
                            />
                        </div>
                        <label className="w-checkbox a-filter-checkbox-field">
                            {/* <div className="w-checkbox-input w-checkbox-input--inputType-custom a-filter-checkbox white"></div>
                            <input
                                id="checkbox-2"
                                type="checkbox"
                                name="checkbox-2"
                                data-name="Checkbox 2"
                                style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                                control-id="ControlID-3"
                            /> */}
                            <span className="paragraph-small w-form-label" >
                                Enter your email to receive Türkiye’s FDI Strategy and subscribe to our Monthly FDI Agenda. Unsubscribe anytime.
                            </span>
                        </label>
                    </div>

                    {isSuccess && (
                        <div className="success-message-3 w-form-done" style={{ display: 'block' }} tabIndex={-1} role="region" aria-label="Email success">
                            <div>Thank You! You'll Receive FDI Strategy and Plans in Your Inbox...</div>
                        </div>
                    )}

                    {isError && (
                        <div className="w-form-fail" style={{ display: 'block' }} tabIndex={-1} role="region" aria-label="Email failure">
                            <div>{isError}</div>
                        </div>
                    )}
                </div>
            </div>

            {/* Close Button */}
            <div className="modal-close-button" onClick={onCloseHandler}>
                <div className="x-button"></div>
            </div>
        </Modal>
    );
};

export default SubscriberModal;
