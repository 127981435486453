import React from 'react';
import { useContent } from 'hooks/useContent';

interface SubscriberLinkProps {
    href: string;
    target?: string;
    className?: string;
    children: React.ReactNode;
}

const SubscriberLink: React.FC<SubscriberLinkProps> = ({ href, target = '_self', className, children }) => {
    const { showSubscriberModal } = useContent();

    const handleOpenModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Tıklamayı durduruyoruz ki modal açalım
        showSubscriberModal();

        // Ardından linki yönlendiriyoruz
        window.open(href, target || '_self');
    };

    return (
        <a href={href} target={target} className={className} onClick={handleOpenModal}>
            {children}
        </a>
    );
};

export default SubscriberLink;
