import { useContent } from 'hooks/useContent';
import Link from 'next/link'
import type { FC } from 'react'
import SubscriberLink from '../forms/SubscriberLink';

export const MainFooter: FC = () => {
  const { content } = useContent();

  return (
    <div className="footer">
      <section className="section-regular footer">
        <div className="container-large">
          <div className="w-layout-grid footer-grid">
            <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd88b-b73dd887" className="footer-column column-1">
              <a href={content.footerMenu.logoLink} aria-current="page" className="footer-logo w-inline-block w--current">
                <img src="/images/fdi-strategy-cover_1.webp" loading="lazy" width="122" sizes="240px" alt="" srcSet="/images/fdi-strategy-cover_1-p-500.png 500w, images/fdi-strategy-cover_1fdi-strategy-cover.webp 800w, images/fdi-strategy-cover_1fdi-strategy-cover.webp 1080w, images/fdi-strategy-cover_1.webp 1600w" className="footer-absolute-image" /></a>
              <SubscriberLink data-w-id="88602e33-63d9-9c80-d71f-f5d64ae8de43" href={content.footerMenu.downloadPdf.link} target="_blank" className="button-left-icon w-inline-block">
                <div className="button-icon-left"></div>
                <div className="text-block-4">{content.footerMenu.downloadPdf.text}</div>
              </SubscriberLink>
            </div>
            <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd89c-b73dd887" className="footer-links-grid-small">
              <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd89d-b73dd887" className="footer-category">
                {content.footerMenu.menuItems.map((menuItem) => {
                  return <>
                    <Link href={menuItem.link} aria-current="page" className="footer-sub-link main w-inline-block w--current">
                      <div className="footer-heading">{menuItem.title}</div>
                    </Link>
                    {menuItem.subLinks && <div className="footer-links-column">
                      {menuItem.subLinks.map((subLink, subItemIndex) => {
                        return <Link href={subLink.link} key={`footer-sub-menu-${subItemIndex}`} className="footer-sub-link w-inline-block">
                          <div>{subLink.title}</div>
                        </Link>
                      })}
                    </div>}
                  </>
                })}

              </div>
              <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8b1-b73dd887" className="footer-category">
                <a href={content.footerMenu.actionPlan.link} className="footer-sub-link main w-inline-block">
                  <div className="footer-heading">{content.footerMenu.actionPlan.title}</div>
                </a>
                <div className="footer-links-column">
                  {content.actionPlanData.policy_areas.map((policyArea, index) => {
                    return <Link href={`/action-plans#0${policyArea.area_no}`} key={index} className="footer-sub-link w-inline-block">
                      <div className="text-block-5">{policyArea.policy_area}</div>
                    </Link>
                  })}
                </div>
              </div>
            </div>
            <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8c8-b73dd887" className="footer-details">
              <div className="footer-row">
                {/* <a href="https://www.invest.gov.tr/en/pages/contact-us.aspx" target='_blank' className="footer-sub-link main right w-inline-block">
                  <div className="footer-heading right-aligned">FOLLOW US  <span className="text-span-2"></span></div>
                </a> */}
                <div className="footer-social-links">
                  <a href={content.socialMedia.facebook} target='_blank' className="footer-social-link w-inline-block">
                    <div className="footer-social"></div>
                  </a>
                  <a href={content.socialMedia.x} target='_blank' className="footer-social-link w-inline-block">
                    <div className="footer-social"></div>
                  </a>
                  <a href={content.socialMedia.instagram} target='_blank' className="footer-social-link w-inline-block">
                    <div className="footer-social"></div>
                  </a>
                  <a href={content.socialMedia.linkedin} target='_blank' className="footer-social-link w-inline-block">
                    <div className="footer-social"></div>
                  </a>
                  <a href={content.socialMedia.youtube} target='_blank' className="footer-social-link w-inline-block">
                    <div className="footer-social"></div>
                  </a>
                </div>
              </div>
              <a href="https://fdiglossary.invest.gov.tr" target='_blank' className="footer-sub-link main right w-inline-block">
                <div className="footer-heading right-aligned">FDI GLOSSARY  <span className="text-span-2"></span></div>
              </a>
              <div className="wrap-v-xsmall">
                <div className="footer-bottom-logo"><img src="/images/invest-in-wide.svg" loading="lazy" alt="" className="logo-visual _50" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-legal-dark">
        <div className="container-large-8">
          <div className="footer-legal-wrap-2">
            <div className="legal-links-small col1">
              {/* <a href="#" className="footer-legal-link-dark">Privacy Policy</a> */}
              {/* <a href="#" className="footer-legal-link-dark">Terms of Service</a> */}
            </div>
            <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8ef-b73dd887" className="legal-links-small col2">
              <a href="https://invest.gov.tr" className="footer-image-link w-inline-block"><img src="/images/fullwhite-wide.svg" loading="lazy" alt="" /></a>
            </div>
            <div id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8f2-b73dd887" className="legal-links-small col3">
              {/* <a href="#" className="footer-legal-link-dark">Contact</a> */}
              {/* <a href="#" className="footer-legal-link-dark">Cookies Settings</a> */}
              <a id="w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8f7-b73dd887" href="#" className="footer-scroll-link">Back to Top ↑</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
