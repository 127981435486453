import React, { type ReactNode, useEffect, useState } from 'react';

interface ModalProps {
    imageUrl?: string;
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode; // Dynamically rendered content
    className?: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, className }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Triggering the animation on opening
            setTimeout(() => {
                setIsVisible(true);
            }, 10); // Delay for triggering the transition after render
        } else {
            setIsVisible(false);
        }
    }, [isOpen]);

    if (!isOpen && !isVisible) return null; // Don't render the modal if it's not open or visible

    return (
        <div className="modal-section">
            <div
                data-w-id="a3568ff9-cc06-f5c1-3306-e3f4ff723faa"
                style={{
                    transform: isVisible
                        ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)'
                        : 'translate3d(0px, 0px, 0px) scale3d(0.7, 0.7, 0.7)',
                    opacity: isVisible ? 1 : 0,
                    transformStyle: 'preserve-3d',
                    transition: 'transform 0.3s ease, opacity 0.3s ease', // Transition for animation
                }}
                className={`modal-container-new first-layer ${className}`}
            >
                {children}
                < div className="modal-close-button" onClick={onClose}>
                    <div className="x-button"></div>
                </div>
            </div>
        </div >
    );
};

export default Modal;
