import { useEffect, type FC, type ReactNode } from 'react'
import { MainFooter } from '@/components/layout/MainFooter'
import { MainHeader } from './MainHeader'
import SubscriberModal from '../forms/SubscriberModal';
import { useContent } from 'hooks/useContent';
import { useRouter } from 'next/router';

export const MainLayout: FC<{ bodyClass?: string; children: ReactNode, optionalSubMenu?: ReactNode, isAnimateMenu?: boolean; }> = props => {
  const { children, optionalSubMenu, isAnimateMenu } = props
  const { isSubscriberModalOpen, hideSubscriberModal } = useContent();
  const router = useRouter();

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1); // '#' işaretini çıkar
      let timeElapsed = 0;
      const intervalDuration = 500; // 500ms aralıklarla kontrol
      const maxTime = 10000; // Maksimum 10 saniye deneme süresi

      const intervalId = setInterval(() => {
        const element = document.getElementById(hash); // hash'e sahip id'yi bul

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' }); // Gidilecek bölüme scroll
          clearInterval(intervalId); // Element bulundu, intervali durdur
        }

        timeElapsed += intervalDuration;
        if (timeElapsed >= maxTime) {
          clearInterval(intervalId); // 10 saniyeyi aşarsa intervali durdur
        }
      }, intervalDuration);
    }
  }, [router.asPath]);

  return (
    <>
      <MainHeader
        isAnimateMenu={isAnimateMenu}
        optionalSubMenu={optionalSubMenu}
      />
      {children}

      <SubscriberModal isOpen={isSubscriberModalOpen} onClose={hideSubscriberModal} />
      <MainFooter />
    </>
  )
}
