import React from 'react';

interface RadialCardData {
  id: string;
  href: string;
  title: string;
}

interface PolicyAreasSectionProps {
  cards: RadialCardData[];
  tagline: string;
  heading: string;
}

const PolicyAreasSection: React.FC<PolicyAreasSectionProps> = ({ cards, tagline, heading }) => {
  return (
    <section className="section-large turqoise">
      <div className="container-large">
        <div id="w-node-c915a300-f121-47df-acce-8975ad92eaec-ad92eaea" className="content-mockup-wrap"></div>
        <div className="radial-grid-system">
          {cards.map((card) => (
            <a
              key={card.id}
              id={card.id}
              href={card.href}
              className="radial-card w-inline-block"
            >
              <div className="wrap-v-tiny">
                <div className="h5-heading light radial-card-heading">{card.title}</div>
              </div>
            </a>
          ))}
          <div id='w-node-c915a300-f121-47df-acce-8975ad92eb03-ad92eaea' className="middle-heading-set">
            <div className="tagline-heading light">{tagline}</div>
            <h2 className="h3-heading middle light">{heading}</h2>
          </div>
        </div>
      </div>
      <div className="mockup-background policy-areas"></div>
      <div className="policy-areas-bg"></div>
    </section>
  );
};

export default PolicyAreasSection;
